<template>
	<div class="page">
		<div class="inf" v-html="inf"></div>
    <img src="../assets/img/lxwm_map.jpg" alt="">
	</div>
</template>
<script>
export default {
	data() {
		return {
			inf: ''
		}
	},
		created() {
		this.basePage()
	},
	methods: {
		basePage() {
			// 页面初始化
			switch (this.$i18n.locale) {
				case 'zh':
					this.getZhDetail()
					break
				case 'en':
					this.getEnDetail()
					break
			}
		},
		getZhDetail() {
			// 中文数据
			this.inf = '<p>厦门沐游网络科技有限公司（简称“沐游网络”），于2018年12月在福建省厦门市集美区成立，沐游网络汇聚了一批拥有丰富经验的优秀团队，专注于移动互联网产品的研发和推广运营。</p> <p> 展望未来，沐游网络在以游戏为核心业务的基础上，将全面升级多元化互动娱乐经营发展，重点打造具备创新性的、全球化的游戏品牌及其他移动互联网产品。大力拓展海内外发行业务，未来产品布局将覆盖角色扮演、动作类、即时战略和休闲益智等多类型游戏，在移动游戏的基础上并会在其他移动互联网产品整体布局。 </p>'
		},
		getEnDetail() {
			// 英文数据
			this.inf = '<p>Xiamen Muyou Network Technology Co., Ltd. (referred to as “Muyou Network”) was established in December 2018 in Software part III Jimei District, Xiamen City, Fujian Province. Muyou Network has gathered a group of experienced and excellent teams, focusing on mobile internet products development and promotion operations.</p> <p>Looking forward to the future, on the basis of the game as the core business, Muyou Network will comprehensively upgrade the development of diversified interactive entertainment business, focusing on creating innovative and global game brands and other mobile Internet products. Vigorously expand the distribution business at domestic market and abroad market, the future product layout will cover multi-type games such as role-playing, action, real-time strategy and casual puzzle, and will be based on mobile games and other mobile Internet products.</p>'
		},
	}
}
</script>
<style scoped>
.page{
  padding-top: 20px;
}
.inf >>> p{
  margin: 20px 0;
  line-height: 30px;
  color: #444;
}
img{
  margin-top: 60px;
}
</style>
